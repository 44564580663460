<div class="date-picker-dialog">
  <app-button
    (click)="dialogRef.close()"
    [size]="ButtonSize.Small"
    [type]="ButtonType.Ghost"
    class="date-picker-dialog__close-button"
  >
    <i
      class="icon-close"
      slot="ghost-icon"
    ></i>
  </app-button>

  @if (dialogData.title) {
    <div class="date-picker-dialog__title">{{ dialogData.title }}</div>
  }

  <div class="date-picker-dialog__date-picker">
    <cc-date-picker
      (dateSelect)="dialogRef.close($event)"
      [maxDate]="dialogData.maxDate"
      [minDate]="dialogData.minDate"
      [selectedDate]="dialogData.selectedDate"
    >
    </cc-date-picker>
  </div>
</div>
