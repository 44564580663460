import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { ButtonComponent } from '@design/buttons/button/button.component';
import { DatePickerComponent } from '@design/forms/date-picker/date-picker.component';

export interface DatePickerDialogData {
  title?: string | undefined;
  selectedDate?: string | undefined;
  minDate?: string | undefined;
  maxDate?: string | undefined;
}

@Component({
  selector: 'cc-date-picker-dialog',
  standalone: true,
  templateUrl: './date-picker-dialog.component.html',
  styleUrls: ['./date-picker-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, DatePickerComponent],
})
export class DatePickerDialogComponent {
  readonly dialogData: DatePickerDialogData = inject(DIALOG_DATA);

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  protected readonly dialogRef: DialogRef<string> = inject(DialogRef);
}
