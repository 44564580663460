import { Component, inject, Input, type OnInit, ViewEncapsulation, output } from '@angular/core';
import { en_US, NzI18nService } from 'ng-zorro-antd/i18n';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { formatISO, startOfDay } from 'date-fns';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';

@UntilDestroy()
@Component({
  selector: 'cc-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NzDatePickerComponent, FormsModule, ReactiveFormsModule],
})
export class DatePickerComponent implements OnInit {
  @Input()
  selectedDate: string | undefined;

  @Input()
  minDate: string | undefined;

  @Input()
  maxDate: string | undefined;

  dateSelect = output<string>();

  calendarFormControl = new FormControl();

  private readonly i18n: NzI18nService = inject(NzI18nService);

  ngOnInit(): void {
    this.i18n.setLocale(en_US);

    this.calendarFormControl.setValue(this.selectedDate);

    this.calendarFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      this.dateSelect.emit(formatISO(new Date(value), { representation: 'date' }));
    });
  }

  checkIfDateIsDisabled(current: Date): boolean {
    if (!this.minDate || !this.maxDate) return false;

    const currentDay = startOfDay(current);

    if (this.minDate && !this.maxDate) {
      const minDateDay = startOfDay(new Date(this.minDate));
      return currentDay < minDateDay;
    }

    if (!this.minDate && this.maxDate) {
      const maxDateDay = startOfDay(new Date(this.maxDate));
      return currentDay > maxDateDay;
    }

    const minDateDay = startOfDay(new Date(this.minDate));
    const maxDateDay = startOfDay(new Date(this.maxDate));
    return currentDay > maxDateDay || currentDay < minDateDay;
  }
}
